<template>
    <div class="mainPage">
        <div class='practiceResult' v-if="practiceDetail">
            <div class="title" @click="goBack">
                <span>
                    <svg-icon icon-class="backIcon" class-name="backIcon"></svg-icon>
                </span>
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/myPracticeBack.png" alt="">
            </div>
            <div class="practiceResult-title">
                <div class="practiceResult-title-left">
                    <h4>{{ practiceDetail.practiceName }}</h4>
                    <p> 练习模式：{{ practicetxt(practiceDetail.practiceModel) }}</p>
                </div>
                <div class="practiceResult-title-right">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/lianxixing.png" alt="">
                </div>

            </div>
            <div class="practiceResult-content">
                <ul class="_clear">

                    <li   
                    v-for="item in practiceResultArr"
                    :key="item.backgroundImg"  
                    :style="{ backgroundImage: 'url(' + item.backgroundImg + ')' }">
                        <div class="practiceResult-content-left">
                            <h2>{{ item.countNum }}</h2>
                            <p>{{item.subStr}}</p>
                        </div>
                    </li>
                </ul>
                <div class="practiceResult-content-bottom">
                    <p class="practiceAgain" @click="practiceAgain">重新练习</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'practiceResult',
    data() {
        return {
            theme: false,
            practiceDetail: null,
            practiceResultArr: []
        };
    },
    created() {
        this.findUserPaperInfo();
    },
    beforeDestroy() {
        // window.removeEventListener("popstate", this.goBack, false); //false阻止默认事件
    },
    methods: {
        practicetxt(type) {
            let txt = null;
            if (type == 1) {
                txt = '顺序练习';
            } else if (type == 2) {
                txt = '随机练习';
            } else if (type == 4) {
                txt = '标注练习';
            } else if (type == 3) {
                txt = '错题练习';
            }
            return txt;
        },
        practiceAgain() {
            // if ((this.practiceDetail.practiceModel === 1 || this.practiceDetail.practiceModel === 2) && this.practiceDetail.totalCount < 1) {
            //     this.$message.warning({ message: '暂无试题', offset: 70 })
            //     return;
            // } else if (this.practiceDetail.practiceModel === 3 && this.practiceDetail.markCount === 0) {
            //     this.$message.warning({ message: '暂无标记试题', offset: 70 })
            //     return;
            // } else if (this.practiceDetail.practiceModel === 4 && this.practiceDetail.errorsCount === 0) {
            //     this.$message.warning({ message: '暂无错题', offset: 70 })
            //     return;
            // }
            // this.joinExam();
           this.goBack()
        },
        practiceShow() {
            this.$router.push({
                path: '/practiceTrainingTask',
                query: {
                    practiceId: this.$route.query.practiceId,
                    businessId: this.$route.query.businessId,
                    businessType: this.$route.query.businessType,
                    practiceModel: this.practiceDetail.practiceModel,
                    userPracticeId: this.$route.query.userPracticeId,
                    practiceShow: true,
                    backPath: this.$route.query.backPath,
                    backModule: this.$route.query.backModule,
                }
            })
        },
        joinExam() {
            this.$router.push({
                path: '/pattern',
                query: {
                    practiceId: this.$route.query.practiceId,
                    businessId: this.$route.query.businessId,
                    businessType: this.$route.query.businessType,
                    practiceModel: this.practiceDetail.practiceModel,
                    userPracticeId: this.userPracticeId,
                    backPath: this.$route.query.backPath,
                    backModule: this.$route.query.backModule,
                }
            })
        },
        goBack() {
            // this.$router.back()
            this.$router.push({
                path: '/pattern',
                query: {
                    businessId: this.$route.query.businessId,
                    practiceId: this.$route.query.practiceId,
                    businessType: 2,
                    backPath: this.$route.query.backPath,
                    backModule: this.$route.query.backModule,
                }
            })
        },
        findUserPaperInfo() {
            let paramsData = {
                userPracticeId: this.$route.query.userPracticeId,
                type: this.$route.query.type
            }
            this.$api.practice.findUserPaperInfo(paramsData).then((res) => {
                if (res.data) {
                    this.practiceDetail = res.data;
                    this.practiceResultArr = [
                        {
                            backgroundImg: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/answerRight.png',
                            subStr: '答对(道）',
                            countNum: this.practiceDetail.rightCount
                        },
                        {
                            backgroundImg: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/answerError.png',
                            subStr: '答错(道）',
                            countNum: this.practiceDetail.errorCount
                        },
                        {
                            backgroundImg: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/answerNo.png',
                            subStr: '未答(道）',
                            countNum: this.practiceDetail.emptyCount
                        },
                        {
                            backgroundImg: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/answerTotal.png',
                            subStr: '总题数(道）',
                            countNum: this.practiceDetail.totalCount
                        },

                    ]



                }
            });
        },
    },

    mounted() {
        // if (window.history && window.history.pushState) {
        //     // 往历史记录里面添加一条新的当前页面的url
        //     history.pushState(null, null, document.URL);
        //     // 给 popstate 绑定一个方法 监听页面刷新
        //     window.addEventListener("popstate", this.goBack, false); //false阻止默认事件
        // }

    },
}

</script>
<style lang="scss" src="../css/practiceResult.scss" scoped></style>

<template>
    <div class="mainPage">
        <div class='pattern'>
            <div class="title" @click="goBack">
                <span>
                    <svg-icon icon-class="backIcon" class-name="backIcon"></svg-icon>
                </span>
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/myPracticeBack.png" alt="">
            </div>
            <div class="pattern-title">
                {{ patternDetail.practiceName }}
            </div>
            <div class="pattern-list">
                <ul>
                    <li @click="gopracticeDetail(1)">
                        <img src="../../../assets/images/exam/sequence.png" alt="">
                        <div class="middle">
                            <h3>顺序学习</h3>
                            <p>全部试题按照顺序进行练习，初次进入练习建议使用顺序练习。</p>
                        </div>
                        <div class="right">
                            <span class="lastTime">上次练习到第<span>{{ patternDetail.commitLastSequence ?
                                0:
                                patternDetail.sequence ||
                                0 }}</span>题&emsp;</span>共计{{ patternDetail.totalCount }}题<svg-icon
                                icon-class="arrow-right"></svg-icon>

                        </div>
                    </li>
                    <li @click="gopracticeDetail(2)">
                        <img src="../../../assets/images/exam/random.png" alt="">
                        <div class="middle">
                            <h3>随机练习</h3>
                            <p>知识点交叉练习，每次进入练习顺序被打乱，适用对基础知识已有基本掌握。</p>
                        </div>
                        <div class="right">
                            共计{{ patternDetail.totalCount || 0 }}题<svg-icon icon-class="arrow-right"></svg-icon>
                        </div>
                    </li>
                    <li @click="gopracticeDetail(3)">
                        <img src="../../../assets/images/exam/mistakes.png" alt="">
                        <div class="middle">
                            <h3>错题本</h3>
                            <p>针对错题巩固练习，答题正确后，试题将移除错题本。</p>
                        </div>
                        <div class="right">
                            共计{{ patternDetail.errorsCount || 0 }}题<svg-icon icon-class="arrow-right"></svg-icon>
                        </div>
                    </li>
                    <li @click="gopracticeDetail(4)">
                        <img src="../../../assets/images/exam/remark.png" alt="">
                        <div class="middle">
                            <h3>我的标记</h3>
                            <p>练习过程中对掌握不深刻的试题标记后，试题将进入”我的标记”，可再次回顾练习。</p>
                        </div>
                        <div class="right">
                            共计{{ patternDetail.markCount || 0 }}题<svg-icon icon-class="arrow-right"></svg-icon>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'practicePattern',
    data() {
        return {
            patternDetail: {},// 练习数据源
            type: null, // 1 顺序学习 2 随机学习 3 错题本 4 我的标记
            infoparams: {}, // url参数
        };
    },
    methods: {
        joinExam() {
            // businessType 练习来源 1 训练营,2:培训任务,3:项目
            // channel 练习来源 0 小程序，1 电脑，2 H5，3 APP
            //channelType：练习来源 1 训练营,2:培训任务,3:项目
            // businessId  项目id
            // userPracticeId  用户练习ID。之前用户参加过练习但是没有交卷的时候，这个值就是上次能过户没有考完的 用户练习ID
            // userPracticeId == lastSequenceUserPracticeId  上一次顺序答题的用户练习记录ID 为null就是从0题开始、不为null就是从上次作答的题目开始练习。
            // 点击顺序练习的时候传这个 lastSequenceUserPracticeId 点击随机练习的时候传这个 lastShuffleUserPracticeId
            let { businessId, businessType,businessName, practiceId } = this.infoparams
            let params = {
                businessId,
                businessType,
                practiceId,
                businessName: businessName,
                type: this.type,
                userPracticeId: this.type == 1 ? this.patternDetail.lastSequenceUserPracticeId : this.patternDetail.lastShuffleUserPracticeId,
                channel: 1,
                channelType: 3,
            }
            this.$router.push({
                path: '/practiceTrainingTask',
                query: {
                    practiceModel: this.type,
                    backPath: this.$route.query.backPath,
                    backModule: this.$route.query.backModule,
                    ...params
                }
            })
        },
        async modelInfo() {
            const responData = await this.$api.practice
                .modelInfo({
                    params: {
                        businessId: this.$route.query.businessId,
                        businessType: this.$route.query.businessType,
                        practiceId: this.$route.query.practiceId
                    }
                })

            this.patternDetail = responData.data;
        },
        gopracticeDetail(type) {
            this.type = type;
            if ((type === 1 || type === 2) && this.patternDetail.totalCount < 1) {
                this.$message.warning({ message: '暂无试题', offset: 70 })
                return;
            } else if (type === 4 && this.patternDetail.markCount === 0) {
                this.$message.warning({ message: '暂无标记试题', offset: 70 })
                return;
            } else if (type === 3 && this.patternDetail.errorsCount === 0) {
                this.$message.warning({ message: '暂无错题', offset: 70 })
                return;
            }
            this.joinExam()
        },
        // to do 返回逻辑需要处理
        goBack() {
            this.$router.push(
                {
                    path:'/learn/list'
                }
            )
        },
    },
    async created() {
        this.infoparams = this.$route.query
        await this.modelInfo();
    },

}

</script>
<style lang="scss" src="../css/pattern.scss"></style>
